// portfolio component
@import "portfolio-navigation"
@import "portfolio-item"

.portfolio
  &__container
    padding: 0
    @media screen and (min-width: $sm)
      padding-left: 2rem
      padding-right: 2rem

  &__item
    margin-bottom: 2rem
    position: relative
    display: block
    overflow: hidden
    @media screen and (min-width: $md)
      margin-bottom: 4rem

    &:last-child
      margin-bottom: 0

    & img
      width: 100%
      transition: all ease 0.5s

    &:after
      content: ' '
      position: absolute
      bottom: 0
      left: 0
      z-index: 2
      width: 100%
      height: 30%
      background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0) 100%)

    &:hover img
      transform: scale(1.01)

  &__title
    font-family: var(--font-secondary)
    font-size: 1.75rem
    padding: 2rem
    color: var(--white)
    position: absolute
    bottom: 0
    left: 0
    z-index: 3
    width: 100%
    @media screen and (min-width: $md)
      font-size: 2rem
      padding: 4rem
