// form component
.form
  &__group
    margin-bottom: 2rem

  &__label
    color: #cccccc
    font-size: 1rem
    width: 100%
    display: block

  &__input
    padding: 0.75rem 0
    border: 0
    background: transparent
    border-bottom: 1px solid #cccccc
    font-size: 1.25rem
    display: block
    width: 100%
    outline: none
    color: #555555

  &__btn
    color: var(--white)
    background-color: var(--black2)
    display: inline-block
    padding: 1rem 3rem
    font-size: 1.125rem
    outline: none
    cursor: pointer
