// portfolio-item
.portfolio-item
  background-color: var(--black)

  &__row
    display: flex
    flex-wrap: wrap

  &__content
    color: #eeeeee
    order: 20
    @media screen and (min-width: $md)
      flex: 0 0 50%
      order: inherit

  &__about
    color: #eeeeee
    order: 10
    @media screen and (min-width: $md)
      flex: 0 0 50%
      padding-left: 10%
      order: inherit

  &__about-item
    margin-bottom: 2.5rem


// portfolio item pagination component
.portfolio-item-pagination
  background-color: var(--black)

  &__row
    display: flex
    justify-content: space-between

  &__divider
    width: 1px
    background-color: #333333

  &__paging
    color: var(--white)
    font-family: var(--font-secondary)
    font-size: 2rem
    flex: 0 0 calc(50% - 1px)
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    padding: 4rem 0
    @media screen and (min-width: $md)
      padding: 6rem 0

    & span
      display: none
      @media screen and (min-width: $md)
        display: block

    & svg
      width: 1.5rem
      height: 1.5rem
      transition: all ease 0.3s
      @media screen and (min-width: $md)
        margin-top: 0.25rem

    &_prev
      &:hover svg
        @media screen and (min-width: $md)
          transform: translateX(-0.25rem)

      & span
        margin-left: 1rem

    &_next
      &:hover svg
        @media screen and (min-width: $md)
          transform: translateX(0.25rem)

      & span
        margin-right: 1rem
