// variables
$sm: 540px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1600px
$xxxl: 1800px

// grid
.container
  width: 100%
  max-width: $xxxl
  padding-left: 2rem
  padding-right: 2rem
  margin: 0 auto

.container-small
  width: 100%
  max-width: $xxl
  padding-left: 2rem
  padding-right: 2rem
  margin: 0 auto

.container-xsmall
  width: 100%
  max-width: 1400px
  padding-left: 2rem
  padding-right: 2rem
  margin: 0 auto

.row
  display: flex
  flex-wrap: wrap

.col3
  flex: 0 0 100%
  margin-bottom: 4rem
  padding-right: 5%
  @media screen and (min-width: $lg)
    flex: 0 0 33%
    margin-bottom: 0

.secp
  padding-top: 5rem
  padding-bottom: 5rem
  @media screen and (min-width: $md)
    padding-top: 8rem
    padding-bottom: 8rem

// display
.dn
  display: none !important

.db
  display: block

.dib
  display: inline-block

.df
  display: flex

// flex alignment
.jcb
  justify-content: space-between

.jcs
  justify-content: start

.jce
  justify-content: end

.aic
  align-items: center

.ais
  align-items: start

.aie
  align-items: end

// text alignment
.tal
  text-align: left
  
.tac
  text-align: center

.tar
  text-align: right

// padding
.pb-1
  padding-bottom: 0.5rem

.pb-2
  padding-bottom: 1rem

.pb-3
  padding-bottom: 1.5rem

.pb-4
  padding-bottom: 2rem

.pb-5
  padding-bottom: 3rem

.pt-1
  padding-top: 0.5rem

.pt-2
  padding-top: 1rem

.pt-3
  padding-top: 1.5rem

.pt-4
  padding-top: 2rem

.pt-5
  padding-top: 3rem

// margin
.ml-a
  margin-left: auto !important

.mr-a
  margin-right: auto !important

.mb-1
  margin-bottom: 0.5rem !important

.mb-2
  margin-bottom: 1rem !important

.mb-3
  margin-bottom: 1.5rem !important

.mb-4
  margin-bottom: 2rem !important

.mb-5
  margin-bottom: 3rem !important

.mt-1
  margin-top: 0.5rem !important

.mt-2
  margin-top: 1rem !important

.mt-3
  margin-top: 1.5rem !important

.mt-4
  margin-top: 2rem !important

.mt-5
  margin-top: 3rem !important

.mr-1
  margin-right: 0.5rem !important

.mr-2
  margin-right: 1rem !important

.mr-3
  margin-right: 1.5rem !important

.mr-4
  margin-right: 2rem !important

.mr-5
  margin-right: 3rem !important

.ml-1
  margin-left: 0.5rem !important

.ml-2
  margin-left: 1rem !important

.ml-3
  margin-left: 1.5rem !important

.ml-4
  margin-left: 2rem !important

.ml-5
  margin-left: 3rem !important

// fonts
.font-bold
  font-weight: 700

.font-medium
  font-weight: 500

.font-regular
  font-weight: 400

.font-light
  font-weight: 300
