// iblock component
.i-block

  &__row
    display: flex
    flex-wrap: wrap
    flex-direction: row
    align-items: flex-start
    -ms-flex-pack: start
    justify-content: flex-start

  &__image, &__caption
    width: 100%
    flex: 0 0 100%
    min-height: 50vh
    flex-shrink: 0
    display: block
    position: relative
    background-color: #1d1e20
    @media screen and (min-width: $md)
      width: 50%
      flex: 0 0 50%
      min-height: auto

      &:before
        pointer-events: none
        user-select: none
        position: relative
        display: block
        content: ''
        width: 100%
        height: auto
        padding-bottom: 100%

  &__caption
    background-color: transparent
    min-height: auto
    @media screen and (min-width: $md)

  &:nth-child(2n) &__caption
    @media screen and (min-width: $md)
      order: 10

  &:nth-child(2n) &__image
    @media screen and (min-width: $md)
      order: 20

  &__img, &__content
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-position: 50% 50%
    background-size: cover

  &__content
    position: relative
    @media screen and (min-width: $md)
      position: absolute

  &__l
    padding: 12%
    @media screen and (min-width: $md)
      position: absolute
      top: 50%
      transform: translateY(-50%)
      max-width: 800px

  &__btn
    color: var(--gold)
