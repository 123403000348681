// order component
.order

  &__row
    display: flex
    flex-wrap: wrap
    flex-direction: row
    align-items: flex-start
    -ms-flex-pack: start
    justify-content: flex-start

  &__image, &__caption
    width: 100%
    flex: 0 0 100%
    flex-shrink: 0
    display: block
    position: relative
    background-color: #1d1e20
    @media screen and (min-width: $md)
      width: 50%
      flex: 0 0 50%
      height: auto

    &:before
      pointer-events: none
      user-select: none
      position: relative
      display: block
      content: ''
      width: 100%
      height: auto
      padding-bottom: 100%


  &__img, &__content
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-position: 50% 50%
    background-size: cover

  &__image
    display: none
    @media screen and (min-width: $md)
      display: block

  &__l
    position: absolute
    top: 50%
    transform: translateY(-50%)
    padding: 12%
    width: 100%
    max-width: 700px

  &__content
    background: #ffffff


// order component
.order-line
  padding-top: 8rem
  padding-bottom: 8rem
