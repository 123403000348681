// gifts component
.gifts
  & .swiper-container
    width: 100%
    height: 100%

  & .swiper-slide
    text-align: center
    font-size: 18px
    background: #fff
    /* Center slide text vertically */
    display: -webkit-box
    display: -ms-flexbox
    display: -webkit-flex
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-justify-content: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-align-items: center
    align-items: center
    margin-bottom: 4rem
    @media screen and (min-width: $md)
      margin-bottom: 7rem

    & img
      border: 2px solid #eeeeee

  & .swiper-pagination-bullets
    bottom: 0 !important
