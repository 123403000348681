// navigation component
.navigation
  display: none
  @media screen and (min-width: $lg)
    display: block

  &__list
    display: flex

  &__item
    padding: 0 0.5rem

  &__link
    color: inherit
    font-size: 1rem
    text-decoration: none
    text-transform: uppercase
