@import "navigation"
@import "mobile-navigation"

.header
  padding-top: 1.5rem
  padding-bottom: 1.5rem
  position: absolute
  top: 0
  left: 0
  width: 100%
  z-index: 5
  transition: opacity ease 0.4s
  @media screen and (min-width: $md)
    padding-top: 2.5rem
    padding-bottom: 2.5rem

  &__row
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    @media screen and (min-width: $md)
      justify-content: initial

  &__burger
    display: block
    margin-right: 2rem
    @media screen and (min-width: $md)
      display: none

    & span
      display: block
      margin: 0.5rem 0
      width: 29px
      height: 1px
      background-color: var(--black)
      transition: all ease 0.2s

    &_open
      & span
        &:nth-child(2)
          opacity: 0

        &:nth-child(1)
          transform: rotate(-45deg) translate3d(-6px, 4px, 0)

        &:nth-child(3)
          transform: rotate(45deg) translate3d(-6px, -4px, 0)

  &_nobg
    background: #ffffff
    color: var(--black)
    box-shadow: 0 0 25px 4px rgba(0,0,0,.1)
    @media screen and (min-width: $md)
      background: transparent
      color: #fafafa
      box-shadow: none
      background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0) 100%)

  &_b
    background: #ffffff
    color: var(--black)
    box-shadow: 0 0 25px 4px rgba(0,0,0,.1)

  &__logo
    @media screen and (min-width: $md)
      margin-right: 2rem

  &__right
    margin-left: auto
    display: none
    @media screen and (min-width: $lg)
      display: block

  &__phone
    color: inherit
    text-decoration: none

  &_scroll
    background: #ffffff
    padding-top: 1rem
    padding-bottom: 1rem
    position: fixed
    color: var(--black)
    box-shadow: 0 0 25px 4px rgba(0,0,0,.1)
    opacity: 0

  &_fade-in
    animation: fadeIn
    animation-duration: 400ms
    animation-fill-mode: forwards
    top: - 10rem

  &_fade-out
    animation: fadeOut
    animation-duration: 400ms
    animation-fill-mode: forwards

@keyframes fadeIn
  0%
    opacity: 0
    top: -10rem
  100%
    opacity: 1
    top: 0

@keyframes fadeOut
  0%
    opacity: 1
    top: 0
  100%
    opacity: 0
    top: -10rem
