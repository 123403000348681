// main component
.main-conception
  min-height: 75vh
  display: flex
  align-items: center
  align-content: center

  &__caption
    max-width: 700px
    padding-left: 2rem
    padding-right: 2rem
    margin: 0 auto

  &__subtitle
    font-size: 1rem
    text-transform: uppercase

  &__title
    font-family: "Viaoda Libre"
    font-size: 3.75rem

  &__description
    font-size: 1.25rem
    font-weight: 300
