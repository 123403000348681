// footer component
.footer
  border-top: 1px solid #333333
  padding: 6rem 0
  background-color: var(--black)

  &__container
    width: 100%
    max-width: $xl
    padding: 0 2rem
    margin: 0 auto

  &__row
    display: flex
    flex-wrap: wrap
    justify-content: center
    @media screen and (min-width: $md)
      justify-content: space-between

  &__logo
    color: #cdcdcd
    margin-bottom: 2rem
    @media screen and (min-width: $md)
      margin-right: 5rem
      margin-bottom: 0

  &__phone
    font-size: 2.125rem
    font-weight: 300
    color: var(--grey)
    @media screen and (min-width: $md)
      text-align: right
      margin-left: auto

// footer-navigation component
.footer-navigation
  display: none
  @media screen and (min-width: $xl)
    display: block
  &__list
    display: flex
    flex-wrap: wrap

  &__item
    flex: 0 0 30%
    padding-top: 0.25rem
    margin-bottom: 0.75rem

  &__link
    font-size: 1rem
    text-transform: uppercase
    color: var(--white)
    font-weight: 300
    opacity: 0.35
    transition: all ease 0.3s

    &:hover
      opacity: 0.5
