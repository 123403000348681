// portfolio-navigation component
.portfolio-navigation
  &__list
    display: flex
    overflow-x: scroll
    @media screen and (min-width: $md)
      overflow-x: unset

    &::-webkit-scrollbar
      height: 0
      width: 0

  &__item
    background-color: transparent
    color: var(--black)
    transition: background-color ease 0.3s

    &:hover
      background-color: #eeeeee

    &_active, &_active:hover
      background-color: var(--black2)
      color: var(--white)

  &__link
    display: block
    color: inherit
    font-size: 1.125rem
    padding: 1rem 2rem
    white-space: nowrap
