// mobile-navigation
.mobile-navigation
  z-index: 3
  position: fixed
  top: 0
  left: -100vw
  width: 100%
  height: 100vh
  background-color: #ffffff
  padding: 7.5rem 0
  font-size: 1.25rem
  transition: all ease 0.3s

  &_open
    transform: translateX(100vw)

  &__list
    margin-bottom: 3rem

  &__item
    padding: 1.75rem 4rem
    border-bottom: 1px solid #eeeeee

  &__link
    display: block
    text-transform: uppercase
    color: var(--grey)
    font-weight: 400

  &__phone, &__address, &__mail
    color: var(--grey)
    padding: 1rem 4rem
    line-height: 1.4
