// slider
.swiper-overlay
  position: relative
  @media screen and (min-width: $md)
    padding-bottom: 50%

  &_bg
    background-color: var(--black)

  &_top
    height: 100vh

  &_g
    padding-bottom: 0 !important


.swiper-container
  left: 0
  height: 100%
  top: 0
  width: 100%
  @media screen and (min-width: $md)
    position: absolute

  &_g
    position: inherit !important

.swiper-nav
  position: absolute
  top: 50%
  left: 0
  right: 0
  width: 100%
  max-width: $xxxl
  margin: 0 auto

.swiper-button-prev, .swiper-button-next
  border-radius: 50%
  width: 3rem
  height: 3rem
  background-color: rgba(0, 0, 0, .8)
  color: var(--white)
  font-size: 2rem
  opacity: 0.5
  transition: all ease 0.3s
  left: 2rem
  right: auto

  &:after
    display: none

  &:hover
    opacity: 0.8

  & svg
    width: 1em
    height: 1em

.swiper-button-next
  transform: rotate(180deg)
  right: 2rem
  left: auto

// bullets
.swiper-pagination-bullets
  display: flex
  justify-content: center
  bottom: 7rem !important

  &_small
    bottom: 3rem !important

.swiper-pagination-bullet
  background-color: transparent
  width: 3.5rem
  height: 10px
  transition: all ease 0.1s
  border-radius: 0
  opacity: 0.75
  cursor: pointer
  border-bottom: 2px solid var(--gold)
  margin: 0 4px 1px !important

  &:hover
    opacity: 1

  &-active
    border-bottom: 4px solid var(--gold)
    margin: 0 4px !important
    opacity: 1

// slide
.slide
  width: 100vw
  background-size: cover

  &__img
    width: 100vw
    height: 100%
    position: relative

    &:after
      content: ' '
      position: absolute
      bottom: 0
      left: 0
      z-index: 2
      width: 100%
      height: 35vh
      background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0) 100%)

  &__caption
    position: absolute
    bottom: 12rem
    width: 100%
    z-index: 4
    max-width: $xxl
    right: 0
    left: 0
    color: var(--white)
    margin: 0 auto
    text-align: center
    @media screen and (min-width: $md)
      text-align: initial

    &_small
      bottom: 6rem

    &-name
      font-size: 3.75rem
      font-family: var(--font-secondary)
      margin-bottom: 3rem
      @media screen and (min-width: $lg)
        font-size: 5.143rem

    &-description
      text-transform: uppercase
      font-weight: 500

    &-btn
      font-size: 1.125rem
      font-weight: 500

      & .btn
        background-color: var(--white)
        color: var(--gold)


.swiper-overlay_small
  .swiper-pagination-bullets
    bottom: 2.5rem !important

  .slide__caption
    bottom: 4rem !important


// scroll down
.scroll-down
  position: absolute
  bottom: 2.5rem
  width: 2.5rem
  height: 2.5rem
  color: var(--white)
  z-index: 4
  left: 0
  right: 0
  margin: 0 auto
  opacity: 0.65

  & svg
    width: 100%
    height: 100%
