// about component
.about
  &_bg
    @media screen and (min-width: $md)
      background-image: url("../images/bgrp.png")
      background-size: contain
      background-position: center bottom
      background-repeat: no-repeat

  &__row
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    // director
  &__dir
    margin-bottom: 3rem
    @media screen and (min-width: $md)
      flex: 0 0 17rem

    &-photo
      margin-bottom: 1rem

    &-name
      font-size: 1.25rem
      margin-bottom: 1rem

    &-position
      color: #7b7b7b

  // content
  &__content
    margin-bottom: 3rem
    @media screen and (min-width: $md)
      flex: 0 0 calc(100% - 20rem)
    @media screen and (min-width: $xl)
      flex: 0 0 calc(50% - 12rem)

    & p
      margin-bottom: 2rem
      font-weight: 400

  &__merit
    flex: 0 0 100%
    @media screen and (min-width: $xl)
      flex: 0 0 calc(50% - 12rem)


// about-merit component
.about-merit
  &__list
    display: flex
    flex-wrap: wrap

  &__item
    margin-bottom: 2rem
    display: flex
    line-height: 1
    flex: 0 0 100%
    @media screen and (min-width: $md)
      flex: 0 0 calc(50% - 1rem)
    @media screen and (min-width: $xl)
      flex: 0 0 100%

  &__digit
    margin-right: 2rem
    font-size: 4.286rem
    flex: 0 0 6rem
    text-align: right
    color: var(--grey)

  &__title
    font-size: 1.714rem
    margin-bottom: 0.5rem
    color: var(--grey)

  &__subtitle
    font-size: 1.143rem
    line-height: 1.6
    color: #aaaaaa
