// logo component

.logo
  text-decoration: none
  text-align: right
  font-size: 0.875rem
  color: inherit

  &__name
    font-family: var(--font-secondary)
    font-size: 2.429em
    display: block
    text-transform: uppercase
    line-height: 1.2

  &__description
    font-family: var(--font-primary)
    font-size: 1em
    display: block
