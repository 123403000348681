@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Viaoda+Libre&display=swap')

\:root
  --font-primary: 'Montserrat', sans-serif
  --font-secondary: 'Viaoda Libre', cursive

  --white: #ffffff
  --gold: #b38f55
  --black: #1d1e20
  --black2: #3f3f3f
  --grey: #666666

html
  font-size: 12px
  line-height: 1.6
  font-family: var(--font-primary)
  font-weight: 400
  color: var(--black2)
  @media screen and (min-width: $sm)
    font-size: 14px

a
  text-decoration: none

p
  line-height: 1.8
  margin-bottom: 2rem

img
  max-width: 100%

.b-hidden
  overflow: hidden
  height: 100%

// bg
.cw
  color: var(--white)

.cg
  color: #eeeeee

.b-gr
  background-color: var(--black)

// btns
.btn
  border: 1px solid
  color: inherit
  padding: 0.625em 1.5em
  font-size: 1em
  display: inline-block

.title
  font-size: 2.5rem
  font-family: var(--font-secondary)
  margin-bottom: 1.5rem
  line-height: 1.2
  @media screen and (min-width: $md)
    font-size: 2.75rem
  @media screen and (min-width: $xl)
    font-size: 3rem

  &_big
    font-size: 3rem
    line-height: 1.2
    @media screen and (min-width: $lg)
      font-size: 4rem

  &_small
    font-size: 1.5rem
    margin-bottom: 1rem

.subtitle
  text-transform: uppercase
  font-weight: 400
  font-size: 1em
  margin-bottom: 1.25rem
  @media screen and (min-width: $lg)
    font-size: 1em

.description
  margin-bottom: 2rem
  font-size: 1em
  font-weight: 400
  line-height: 1.7

.divider-header
  margin-top: 4rem
  @media screen and (min-width: $md)
    margin-top: 6rem
