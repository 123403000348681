// contact component
.contact
  &__row
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  &__content
    flex: 0 0 100%
    @media screen and (min-width: $md)
      flex: 0 0 30rem

    & p
      font-size: 1.125rem

  &__map
    flex: 0 0 100%
    @media screen and (min-width: $md)
      flex: 0 0 calc(100% - 35rem)
